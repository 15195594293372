// Guards the data coming in to make sure it's a job
export const isJobData = (x) => {
    if (!Array.isArray(x)) {
        return false;
    }
    if (x.length !== 4) {
        return false;
    }
    return x.every(item => typeof item === 'string');
};
