import { __awaiter, __decorate } from "tslib";
import '@slickdeals/blueprint-twig/javascript/components/button';
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import { mediatable } from '@slickdeals/blueprint-twig/javascript/decorators/mediatable';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import '../../scss/recipes/holidaySeoBlock.scss';
export const name = 'HolidaySeoBlock';
/** Classnames for this module */
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-r-holidaySeoBlock";
    ClassNames["JavaScript"] = "js-holidaySeoBlock";
    ClassNames["Text"] = "bp-r-holidaySeoBlock_readMoreText";
    ClassNames["TextMore"] = "bp-r-holidaySeoBlock_readMoreText--show";
    ClassNames["Button"] = "bp-r-holidaySeoBlock_readMore";
    ClassNames["ButtonShow"] = "bp-r-holidaySeoBlock_readMore--show";
})(ClassNames || (ClassNames = {}));
export var ButtonState;
(function (ButtonState) {
    ButtonState["Default"] = "Read More";
    ButtonState["On"] = "Read Less";
})(ButtonState || (ButtonState = {}));
/** Mediated events triggered by this module */
export var MediatedEvents;
(function (MediatedEvents) {
    MediatedEvents["Click"] = "click";
})(MediatedEvents || (MediatedEvents = {}));
let HolidaySeoBlock = class HolidaySeoBlock extends BlueprintComponent {
    constructor(options) {
        super(name, options);
        this.options = options;
        this.readMoreOnClick = () => {
            const textToChange = this.readMoreButton.childNodes[0];
            this.text.classList.toggle(ClassNames.TextMore);
            if (textToChange.nodeValue === ButtonState.Default) {
                textToChange.nodeValue = ButtonState.On;
                return;
            }
            textToChange.nodeValue = ButtonState.Default;
        };
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = (yield BlueprintComponent.loadElement(name, this.options));
            this.text = this.element.querySelector(`.${ClassNames.Text}`);
            this.readMoreButton = this.element.querySelector(`.${ClassNames.Button}`);
            this.readMoreButton.addEventListener(MediatedEvents.Click, this.readMoreOnClick.bind(this));
        });
    }
};
HolidaySeoBlock = __decorate([
    injectable(name),
    mediatable(name, Object.values(MediatedEvents))
], HolidaySeoBlock);
export default HolidaySeoBlock;
BlueprintComponent.instantiate(name);
