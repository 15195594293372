import { AdEventListeners } from '@slickdeals/ads-core';
import { onscrollend } from './scrollEvents';
export default class AdRefreshEvents {
    constructor() {
        this.initializeAdRefreshOnUpscroll = () => {
            window.addEventListener('scroll', () => {
                this.isUpscroll = ((document.body.getBoundingClientRect()).top > this.scrollPosition);
                this.scrollPosition = (document.body.getBoundingClientRect()).top;
            });
            onscrollend(() => {
                if (this.isUpscroll) {
                    document.dispatchEvent(new Event(AdEventListeners.UserInitiateRefresh));
                }
            });
        };
        this.isUpscroll = false;
        this.scrollPosition = 0;
    }
}
