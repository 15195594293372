import { name as SubscriberName, QOSLevels } from '@slickdeals/blueprint-twig/javascript/queues/jobQueue/jobSubscriber';
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import { impressionTopic } from '../patterns/dealCard';
import { isJobData } from '../utilities/guards';
/** We want to have the subscriber active immediately */
const subscriber = Injector.get(SubscriberName, InjectorScope.Singleton);
// change to let be able to reset
let jobBag = [];
let dispatchTimeout;
export const initializeDealCardSubscriber = () => {
    subscriber.subscribe(impressionTopic, QOSLevels.QOS1, (job) => {
        const rawData = job.getJobKey().split('_');
        if (!isJobData(rawData)) {
            return;
        }
        jobBag.push({
            threadId: rawData[0],
            page: rawData[1],
            moduleId: rawData[2],
            indexInModule: rawData[3],
        });
        // setup a timeout that sends the job bag off to marty and then clears it here
        if (dispatchTimeout) {
            clearTimeout(dispatchTimeout);
        }
        dispatchTimeout = setTimeout(() => {
            // TODO: Setup a fetch call
            // fetch('/some/endpoint.php', {
            //   method: 'post',
            //   body: JSON.stringify(jobBag),
            // });
            jobBag = [];
        }, 200);
    });
};
