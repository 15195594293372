import { __awaiter, __decorate } from "tslib";
import DOMPurify from 'dompurify';
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import Logger from '@slickdeals/durango/dist/javascript/utilities/logger';
import '../../scss/recipes/cashbackOffersWidget.scss';
import '../patterns/featuredOffersTile';
/** The name of the module */
export const name = 'CashbackOffersWidget';
/** Class names for the component */
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-r-cashbackOffersWidget";
    ClassNames["JavaScript"] = "js-cashbackOffersWidget";
    ClassNames["Invisible"] = "bp-r-cashbackOffersWidget--invisible";
})(ClassNames || (ClassNames = {}));
let CashbackOffersWidget = class CashbackOffersWidget extends CatalogItem {
    constructor(options) {
        super(options);
        this.options = options;
    }
    /**
     * Perform any necessary initialization for the component
     */
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield CatalogItem.loadElement(name, this.options);
            this.fetchTiles();
        });
    }
    fetchTiles() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // fetch offer tiles html from ajax endpoint
                const res = yield fetch('/cashback/cashback-offers-widget', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'text/html',
                    },
                });
                if (!res.ok) {
                    throw new Error(res.statusText);
                }
                // sanitize html
                const html = DOMPurify.sanitize(yield res.text(), {
                    RETURN_DOM_FRAGMENT: true,
                    USE_PROFILES: { html: true },
                });
                // append html to element and make visible
                this.element.appendChild(html);
                this.element.classList.remove(ClassNames.Invisible);
            }
            catch (e) {
                Logger.error(e);
            }
        });
    }
};
CashbackOffersWidget = __decorate([
    injectable(name),
    catalogItem(name)
], CashbackOffersWidget);
export default CashbackOffersWidget;
