let timer;
export const onscrollend = (callback) => {
    if ('onscrollend' in window) {
        document.onscrollend = callback;
    }
    else {
        document.onscroll = () => {
            clearTimeout(timer);
            timer = setTimeout(callback, 100);
        };
    }
};
