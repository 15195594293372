import { __awaiter } from "tslib";
/** The name of the module */
export const name = 'PreFetchService';
class PreFetchService {
    /**
       * Fetch data from the prefetch queue
       *
       * @param key Key for data to look up
       */
    static getData(key) {
        return __awaiter(this, void 0, void 0, function* () {
            // Use a promise since we're using a nested function
            return new Promise((resolve, reject) => {
                try {
                    // Request data from the prefetch queue, and register callback to resolve or reject if there's data
                    window.immediateFetch.getData(key, resolve);
                }
                catch (_a) {
                    reject();
                }
            });
        });
    }
    static hasKey(key) {
        return window.immediateFetch.hasKey(key);
    }
    static addCallback(key, callback) {
        window.immediateFetch.addCallback(key, callback);
    }
}
export default PreFetchService;
