var JustForYouCarouselWrapper_1;
import { __awaiter, __decorate } from "tslib";
import DOMPurify from 'dompurify';
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import { classNameWithModifier } from '@slickdeals/durango/dist/javascript/utilities/css';
import Logger from '@slickdeals/durango/dist/javascript/utilities/logger';
import './justForYouCarousel';
import Tooltip, { TooltipPosition } from '@slickdeals/blueprint-twig/javascript/components/tooltip';
import '../../scss/patterns/justForYouCarsouelWrapper.scss';
import PreFetchService from '../services/preFetchService';
export const name = 'JustForYouCarouselWrapper';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-justForYouCarouselWrapper";
    ClassNames["Javascript"] = "js-justForYouCarouselWrapper";
    ClassNames["CarouselContainer"] = "js-justForYouCarouselWrapper_carouselContainer";
    ClassNames["TooltipContainer"] = "js-justForYouCarouselWrapper_Tooltip";
})(ClassNames || (ClassNames = {}));
const purifyHtml = (html) => {
    const purified = DOMPurify.sanitize(html, {
        RETURN_DOM_FRAGMENT: true,
        ADD_ATTR: ['form'],
        USE_PROFILES: { html: true },
    });
    return purified;
};
let JustForYouCarouselWrapper = JustForYouCarouselWrapper_1 = class JustForYouCarouselWrapper extends CatalogItem {
    constructor(options) {
        super(options);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = (yield CatalogItem.loadElement(name, this.options));
            new Tooltip({
                triggerElement: this.element.querySelector(`.${ClassNames.TooltipContainer}`),
                contents: 'Recommendations based on deals you\'ve viewed on Slickdeals',
                modifiers: {
                    position: TooltipPosition.TopCenter,
                },
            });
            yield this.loadDeals();
        });
    }
    loadDeals() {
        return __awaiter(this, void 0, void 0, function* () {
            let response;
            try {
                response = yield JustForYouCarouselWrapper_1.getServerRenderedHtml();
            }
            catch (e) {
                Logger.error(e);
                this.element.remove();
            }
            if (!response.html) {
                this.element.remove();
                return;
            }
            this.injectHtml(response);
        });
    }
    injectHtml(response) {
        this.element.querySelector(`.${ClassNames.CarouselContainer}`).appendChild(purifyHtml(response.html));
        this.element.classList.remove(classNameWithModifier(ClassNames.Default, 'invisible'));
        this.element.classList.add(classNameWithModifier(ClassNames.Default, 'visible'));
    }
    static getServerRenderedHtml() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!PreFetchService.hasKey(JustForYouCarouselWrapper_1.immediateFetchKey)) {
                return { html: '', renderPlaceholder: false };
            }
            try {
                const jfyCarouselPayload = yield PreFetchService.getData(JustForYouCarouselWrapper_1.immediateFetchKey);
                // Parse the JSON and return the offers
                return JSON.parse(jfyCarouselPayload);
            }
            catch (_a) {
                throw Error('Failed to fetch JFY carousel');
            }
        });
    }
};
JustForYouCarouselWrapper.immediateFetchKey = 'jfyCarousel';
JustForYouCarouselWrapper = JustForYouCarouselWrapper_1 = __decorate([
    catalogItem(name),
    injectable(name)
], JustForYouCarouselWrapper);
export default JustForYouCarouselWrapper;
